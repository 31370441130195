import React from 'react'
import PropTypes from 'prop-types'
import ConfirmLayout from 'src/templates/confirm'
import PageHead from 'src/components/head/PageHead'

// Main component:
const RobinhoodRedirectPage = ({ location }) => {
  const deepLinkPath = `robinhood${location.search}`
  return (
    <ConfirmLayout
      iconSize="largest"
      page="robinhood"
      deepLinkPath={deepLinkPath}
      location={location}
      backgroundGradient
    />
  )
}

// Main component props:
RobinhoodRedirectPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

RobinhoodRedirectPage.defaultProps = {}

export default RobinhoodRedirectPage

// <head> component:
export function Head() {
  return <PageHead page="robinhood" noindex />
}
